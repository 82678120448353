<template>
  <div class="box">
    <el-header style="text-align: right; font-size: 12px">
      <!-- 	<el-dropdown>
				<i class="el-icon-message-solid" style="margin-right: 15px"></i>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item>查看</el-dropdown-item>
					<el-dropdown-item>新增</el-dropdown-item>
					<el-dropdown-item>删除</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>

			<el-dropdown>
				<i class="el-icon-s-operation" style="margin-right: 15px"></i>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item>查看</el-dropdown-item>
					<el-dropdown-item>新增</el-dropdown-item>
					<el-dropdown-item>删除</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown> -->
    </el-header>
  </div>
</template>
<script>
export default {
  name: "top",
};
</script>

<style lang="less" scoped>
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

/deep/ .el-dropdown {
  font-size: 16px;
}
</style>
