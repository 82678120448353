<template>
  <div class="about">
    <el-container style="height: 920px; border: 1px solid #eee">
      <el-aside width="50px" style="background-color: rgb(100, 100, 100)">
        <img class="topImg" src="https://image.taoqiandao.com/my.png" alt="" />
      </el-aside>
      <el-aside
        class="aside-two"
        width="200px"
        style="background-color: rgb(238, 241, 246)"
      >
        <el-menu
          width="120px"
          :default-active="$route.fullPath"
          router
          @open="handleOpen"
          @close="handleClose"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
          <el-submenu index="0">
            <template slot="title"><i class="el-icon-menu"></i>工作台</template>
            <el-menu-item-group> </el-menu-item-group>
          </el-submenu>
          <el-submenu index="/mainItem/order">
            <template slot="title"
              ><i class="el-icon-goods"></i>订单管理</template
            >
            <el-menu-item-group>
              <el-menu-item
                index="/mainItem/order/orderData/index"
                @click="rightPage()"
                >订单列表
              </el-menu-item>
            </el-menu-item-group>
            <el-submenu index="1">
              <template slot="title">订单记录</template>
              <el-menu-item-group>
                <el-menu-item
                  index="/mainItem/order/orderRecords/telephone/index"
                  @click="rightPage()"
                >
                  话费</el-menu-item
                >
                <el-menu-item
                  index="/mainItem/order/orderRecords/electricity/index"
                  @click="rightPage()"
                  >电卡</el-menu-item
                >
                <el-menu-item
                  index="/mainItem/order/orderRecords/oil/index"
                  @click="rightPage()"
                  >油卡
                </el-menu-item>
                <el-menu-item
                  index="/mainItem/order/orderRecords/orderRecords/coupon/index"
                  @click="rightPage()"
                  >影音卡券</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
          </el-submenu>
          <el-submenu index="/mainItem/user">
            <template slot="title"
              ><i class="el-icon-s-custom"></i>用户</template
            >
            <el-menu-item-group>
              <el-menu-item index="/mainItem/user/UserList"
                >用户管理</el-menu-item
              >
              <el-menu-item index="/mainItem/user/RealNameAudit"
                >实名审核</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="/mainItem/goods">
            <template slot="title"><i class="el-icon-goods"></i>商品</template>
            <el-menu-item-group>
              <el-menu-item index="/mainItem/goods/GoodsList"
                >商品列表</el-menu-item
              >
              <el-menu-item index="/mainItem/goods/Discount"
                >优惠券制作</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="/mainItem/goods2">
            <template slot="title"
              ><i class="el-icon-setting"></i>分类</template
            >
            <el-menu-item-group>
              <el-menu-item index="/mainItem/goods/TypeList"
                >分类列表</el-menu-item
              >
              <el-menu-item index="/mainItem/goods/Label"
                >标签设置</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="/mainItem/channel">
            <template slot="title"><i class="el-icon-share"></i>渠道</template>
            <el-menu-item-group>
              <el-menu-item index="/mainItem/channel/ChannelBusiness"
                >渠道商</el-menu-item
              >
              <el-menu-item index="/mainItem/channel/Supplier"
                >供应商</el-menu-item
              >
              <el-menu-item index="/mainItem/channel/Service"
                >服务商</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="/mainItem/material">
            <template slot="title"
              ><i class="el-icon-sell"></i>文档撰写</template
            >
            <el-menu-item-group>
              <el-menu-item index="/mainItem/material/earthPushing"
                >地推物料</el-menu-item
              >
              <el-menu-item index="/mainItem/material/earthPushingCategory"
                >地推物料分类</el-menu-item
              >
              <el-menu-item index="/mainItem/material/Propaganda"
                >宣传素材</el-menu-item
              >
              <el-menu-item index="/mainItem/material/Website"
                >官网</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="/mainItem/poster">
            <template slot="title"><i class="el-icon-sell"></i>海报</template>
            <el-menu-item-group>
              <el-menu-item index="/mainItem/poster/PosterList"
                >海报列表</el-menu-item
              >
              <el-menu-item index="/mainItem/poster/PosterAdd"
                >海报上传</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="/mainItem/finance">
            <template slot="title"
              ><i class="el-icon-document"></i>财务</template
            >
            <el-menu-item-group>
              <el-menu-item index="/mainItem/finance/Withdrawal"
                >提现列表</el-menu-item
              >
              <el-menu-item index="/mainItem/finance/Recharge"
                >充值记录</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>

          <el-submenu index="/mainItem/message">
            <template slot="title"><i class="el-icon-s-data"></i>消息</template>
            <el-menu-item-group>
              <el-menu-item index="/mainItem/message/MessageList"
                >消息列表</el-menu-item
              >
              <el-menu-item index="/mainItem/message/Release"
                >消息发布</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>

      <el-container class="right-container">
        <!-- 右页面头部 -->
        <Top></Top>

        <el-main v-loading="false" class="homepage-main">
          <keep-alive>
            <!-- 这个router-view里的内容未来要切换，所以给他加上keep-alive -->
            <router-view :key="$router.fullPath"></router-view>
          </keep-alive>
        </el-main>

        <el-footer style="text-align: right; height: 0; font-size: 12px">
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import Top from "../components/topItem/Top.vue";
export default {
  name: "HomePage",
  components: {
    Top,
  },
  data() {
    const item = {
      date: "2016-05-02",
      name: "王小虎",
      address: "上海市普陀区金沙江路 1518 弄",
    };
    return {
      tableData: Array(20).fill(item),
    };
  },
  methods: {
    rightPage() {
      console.log("rightpage");
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  created() {},
};
</script>
<style lang="less" scoped>
.el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

/deep/ .right-container {
  padding-left: 15px;
  background-color: #f9f9f9;
}

/deep/ .el-submenu {
  text-align: left;
}

.el-aside {
  text-align: center;
  color: #333;
}

.aside-two {
  background-color: rgb(84, 92, 100) !important;
}

/deep/ .el-card__body,
.el-main {
  padding: 0;
  // padding-right: 15px;
}

.topImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: 10px;
}

/deep/ .el-header[data-v-eaca4064] {
  margin-bottom: 15px;
}
</style>
