import axios from "axios";
import store from "@/store/index.js";
import { BaseUrl } from "@/api/setting.js";
axios.defaults.timeout = 5000;
axios.defaults.baseURL = BaseUrl;

// 创建axios实例
const service = axios.create({
  timeout: 15000, // 请求超时时间
});
//http request 拦截器
service.interceptors.request.use(
  (config) => {
    // const token = getCookie('名称');注意使用的时候需要引入cookie方法，推荐js-cookie
    config.data = JSON.stringify(config.data);
    config.withCredentials = false;
    // if(token){
    //   config.params = {'token':token}
    // }
    if (store.state.access_token) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.Authorization =
        store.state.token_type + " " + store.state.access_token;
      //   console.log('interceptors config=',config)
      console.log("这个是请求拦截");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//http response 拦截器
service.interceptors.response.use(
  (response) => {
    if (response.data.status_code === 401) {
      console.log(response.statusCode);
      return false;
    }
    if (response.data.status_code === 421) {
      console.log(response.statusCode);
      console.log("手机授权登录");
      uni.reLaunch({
        url: "/pages/login/index",
      });
      return false;
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { service };
/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: data,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

// form-data 请求接口
export function postFormData(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: url,
      data: data,
      transformRequest: [
        function (data) {
          let ret = "";
          for (let it in data) {
            ret +=
              encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
          }
          ret = ret.substring(0, ret.lastIndexOf("&"));
          return ret;
        },
      ],
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
